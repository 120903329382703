import { FormikHelpers, FormikValues } from "formik"
import { useTranslation } from "react-i18next"
import useQueryPost from "../../Custom Hooks/useQueryPost"
import { FormikContainer } from "../Forms"
import schema from "../../Utils/Validations/Company/schema"
import { FC, useCallback } from "react"
import { InputsArray } from "../../Assets/Data/Dashboard/Company"
import CompanyService from "../../Services/CompanyService"
type AddModalProps = {
    handleClose: () => void
}
const AddModal: FC<AddModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const [handleSubmit,] = useQueryPost(CompanyService.store, ["add-company"], {
        success: t("added-succssefully"),
    }, ["get-companies"], () => {
        handleClose()
    })
    const initialValues = {
        name_en: "",
        name_ar: "",
        location: "",
        website: ""

    }
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit(values)
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])
    return (
        <>
            <FormikContainer
                className=""
                arrayOfInputs={InputsArray().inputs}
                onSubmit={onSubmit}
                initialValues={initialValues}
                btnText={t("add")}
                schema={schema} />
        </>
    )
}

export default AddModal