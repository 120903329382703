import { FormikHelpers, FormikValues } from "formik"
import { useTranslation } from "react-i18next"
import useQueryPost from "../../Custom Hooks/useQueryPost"
import { FormikContainer } from "../Forms"
import schema from "../../Utils/Validations/LeadSource/schema"
import { FC, useCallback, useContext } from "react"
import { TableContext } from "../../Context/TableContext"
import { InputsArray } from "../../Assets/Data/Dashboard/LeadSource"
import LeadSourceService from "../../Services/LeadSourceService"
type AddModalProps = {
    handleClose: () => void
}
const AddModal: FC<AddModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [handleSubmit,] = useQueryPost(LeadSourceService.store, ["add-lead-source"], {
        success: t("added-succssefully"),
    }, ["get-lead-source"], () => {
        handleClose()
    })
    const initialValues = {
        name_en: "",
        name_ar: "",
    }
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit({ ...values, id: recordId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])
    return (
        <>
            <FormikContainer
                className=""
                arrayOfInputs={InputsArray().inputs}
                onSubmit={onSubmit}
                initialValues={initialValues}
                btnText={t("add")}
                schema={schema} />
        </>
    )
}

export default AddModal