import { FormikHelpers, FormikValues } from "formik"
import { useTranslation } from "react-i18next"
import useQueryPost from "../../Custom Hooks/useQueryPost"
import { FormikContainer } from "../Forms"
import schema from "../../Utils/Validations/Lead/schema"
import { FC, useCallback, useContext } from "react"
import { TableContext } from "../../Context/TableContext"
import { InputsArray } from "../../Assets/Data/Dashboard/Lead"
import LeadsService from "../../Services/LeadsService"
type AddModalProps = {
    handleClose: () => void
}
const AddModal: FC<AddModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [handleSubmit,] = useQueryPost(LeadsService.store, ["add-lead"], {
        success: t("added-succssefully"),
    }, ["get-lead-by-id"], () => {
        handleClose()
    })
    const initialValues = {
        title: "",
        status: "",
        contact_id: "",
        lead_source_id: "",
        lead_type_id: "",
        company_id: "",
        notes: "",
        date_of_last_contact: "",
        date_of_next_contact: "",
        expected_revenue: "",
        expected_close_date: ""
    }
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit({ ...values, id: recordId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])
    return (
        <>
            <FormikContainer
                className=""
                arrayOfInputs={InputsArray().inputs}
                onSubmit={onSubmit}
                initialValues={initialValues}
                btnText={t("add")}
                schema={schema} />
        </>
    )
}

export default AddModal