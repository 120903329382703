import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn, ModalComponent } from "../../../Components"
import "./Lead.css"
import { ToastContainer } from "react-toastify"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import useModals from "../../../Custom Hooks/useModals"
import { useCallback, useState } from "react"
import LeadTypesService from "../../../Services/LeadTypesService"
import LeadsService from "../../../Services/LeadsService"
import { LeadCols, LeadModals } from "../../../Assets/Data/Dashboard/Lead"
import Cookies from "js-cookie"

const Lead = () => {
    const { t, i18n } = useTranslation()
    const [leadId, setLeadId] = useState<number>(1)
    const [page, setPage] = useState<number>(1)
    const [Leads, , , loading] = useQueryGet(() => LeadsService.getLeadById(leadId), ["get-lead-by-id", leadId], undefined, {
        mainLoading: true
    })

    const [leadsTypes, , , loadingLead] = useQueryGet(LeadTypesService.getAll, ["get-lead-types"], undefined, {
        mainLoading: true
    })
    const [permissions, setPermissions] = useState(JSON.parse(Cookies.get("permissions") || ""))
    const { show, handleClose, handleShow } = useModals({
        addLead: false,
        editLead: false,
        viewLead: false
    })
    const { cols } = LeadCols(handleShow, t, i18n.language, permissions)
    const { modals } = LeadModals(handleClose, t)
    const memoizedHandleShow = useCallback(() => {
        handleShow("addLead")
    }, [])
    const leadTypes = leadsTypes?.data?.data?.data.map((item: any) => (
        <div className="col" onClick={() => setLeadId(item.id)} key={item.id}>
            <BoxWithShadow additionalClass={`file-white-con ${item.id == leadId && "border-primary"} leads-box`}>
                <h2>{i18n.language == "en" ? item.name_en : item.name_ar}</h2>
                <p>{item.description.slice(0, 50)}....</p>
            </BoxWithShadow>
        </div>
    ))
    return (
        <>
            <BoxWithShadow additionalClass={`container-fluid users leads`}>
                <div className="row row-cols-4 mb-5">
                    {leadTypes}
                </div>
                <div className="flex-between-start">
                    <BoxTitle title={t("leads")} />
                    {permissions.includes("Create Lead Action") && <MainBtn title={t("add")} onClick={memoizedHandleShow} />}
                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={Leads?.data?.data?.data}
                    columns={cols}
                    pagination={{
                        pageSize: Leads?.data?.data?.per_page,
                        total: Leads?.data?.data?.total,
                        onChange: (page) => setPage(page)
                    }}
                />
                <ModalComponent show={show} handleClose={handleClose} arrayOfModals={modals} />
                <ToastContainer />
            </BoxWithShadow>

        </>
    )
}

export default Lead