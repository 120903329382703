import { RouteObject } from "react-router-dom";
import { Company, Contact, Lead, LeadActions, LeadSource, LeadTypes } from "../../Pages";


const MarkitingRoutes: RouteObject[] = [
    {
        path: "lead-type",
        Component: LeadTypes,
    },
    {
        path: "lead-source",
        Component: LeadSource,
    },
    {
        path: "contact",
        Component: Contact,
    },
    {
        path: "companys",
        Component: Company,
    },
    {
        path: "leads",
        Component: Lead,
    },
    {
        path: "lead-actions/:id",
        Component: LeadActions,
    },
]
export default MarkitingRoutes