import { useContext } from "react"
import { DropDownMenu } from "../../../../../Components"
import DeductionTableActionsDropdown from "./DeductionTableActionsDropdown"
import { TableContext } from "../../../../../Context/TableContext"


const DeductionTableCols = (t: (key: string) => string, handleShow: (name: string) => void, permissions: any) => {
    const { setRecordId, setTableName } = useContext(TableContext)
    const cols = [
        {
            title: t("amount"),
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: t("date"),
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: t("Description"),
            dataIndex: 'description',
            key: 'description',
        },
        permissions.includes("Make Actions On Salary") ? {
            title: t("actions"),
            dataIndex: 'id',
            key: 'id',
            render: (id: number) => <>
                <DropDownMenu
                    children={t("actions")}
                    arrayOfItems={DeductionTableActionsDropdown(id, handleShow, t, setRecordId, setTableName).items}
                    className="action-table"
                />
            </>
        } : {
            title: "",
            dataIndex: '',
            key: ''
        },

    ]
    return { cols }
}

export default DeductionTableCols