import { AuthAxios } from "./AxiosHandler"

class LeadsService {
    static endPoint = "lead"
    static getLeadById(id: number) {
        return AuthAxios.get(`${LeadsService.endPoint}/${id}`)
    }
    static getCreateData() {
        return AuthAxios.get(`${LeadsService.endPoint}/get-create-data`)
    }
    static store(body: any) {
        return AuthAxios.post(`${LeadsService.endPoint}/store`, body)
    }
    static update(body: any) {
        return AuthAxios.post(`${LeadsService.endPoint}/update`, body)
    }
    static find(id: any) {
        return AuthAxios.get(`${LeadsService.endPoint}/find/${id}`)
    }
}
export default LeadsService