import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn, ModalComponent } from "../../../Components"
import "./LeadSource.css"
import { ToastContainer } from "react-toastify"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import useModals from "../../../Custom Hooks/useModals"
import { useCallback, useState } from "react"
import LeadSourceService from "../../../Services/LeadSourceService"
import { LeadSourceCols, LeadSourceModals } from "../../../Assets/Data/Dashboard/LeadSource"
import Cookies from "js-cookie"

const LeadSource = () => {
    const { t, i18n } = useTranslation()
    const [dataSource, , , loading] = useQueryGet(LeadSourceService.getAll, ["get-lead-source"], undefined, {
        mainLoading: true
    })
    const [page, setPage] = useState<number>(1)
    const [permissions, setPermissions] = useState(JSON.parse(Cookies.get("permissions") || ""))
    const { show, handleClose, handleShow } = useModals({
        addLeadSource: false,
        editLeadSource: false
    })
    const { cols } = LeadSourceCols(handleShow, t, i18n.language, permissions)
    const { modals } = LeadSourceModals(handleClose, t)
    const memoizedHandleShow = useCallback(() => {
        handleShow("addLeadSource")
    }, [])
    return (
        <>
            <BoxWithShadow additionalClass={`container-fluid users`}>
                <div className="flex-between-start">
                    <BoxTitle title={t("lead-source")} />
                    {permissions.includes("Make Actions On Lead Source") && <MainBtn title={t("add")} onClick={memoizedHandleShow} />}

                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={dataSource?.data?.data?.data}
                    columns={cols}
                    pagination={{
                        pageSize: dataSource?.data?.data?.per_page,
                        total: dataSource?.data?.data?.total,
                        onChange: (page) => setPage(page)
                    }}
                />
                <ModalComponent show={show} handleClose={handleClose} arrayOfModals={modals} />
                <ToastContainer />
            </BoxWithShadow>

        </>
    )
}

export default LeadSource