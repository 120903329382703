import { FormikHelpers, FormikValues } from "formik"
import { useTranslation } from "react-i18next"
import useQueryPost from "../../Custom Hooks/useQueryPost"
import { FormikContainer } from "../Forms"
import schema from "../../Utils/Validations/Contact/schema"
import { FC, useCallback } from "react"
import { InputsArray } from "../../Assets/Data/Dashboard/Contact"
import ContactService from "../../Services/ContactService"
type ContactProps = {
    handleClose: () => void
}
const Contact: FC<ContactProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const [handleSubmit,] = useQueryPost(ContactService.store, ["add-contact"], {
        success: t("added-succssefully"),
    }, ["get-contact"], () => {
        handleClose()
    })
    const initialValues = {
        name: "",
        job_title: "",
        email: "",
        mobile: "",
    }
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit(values)
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])
    return (
        <>
            <FormikContainer
                className=""
                arrayOfInputs={InputsArray().inputs}
                onSubmit={onSubmit}
                initialValues={initialValues}
                btnText={t("add")}
                schema={schema} />
        </>
    )
}

export default Contact