import { useTranslation } from "react-i18next"
import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes"
import { useMemo } from "react"
import useQueryGet from "../../../../Custom Hooks/useQueryGet"
import CompanyService from "../../../../Services/CompanyService"
import LeadsService from "../../../../Services/LeadsService"


const InputsArray = () => {
    const { t, i18n } = useTranslation()
    const [dataSource,] = useQueryGet(LeadsService.getCreateData, ["get-create-data"])
    const companys = dataSource?.data?.data?.companies?.map((company: any) => {
        return {
            id: company.id,
            title: i18n.language == "en" ? company?.name_en : company?.name_ar,
            value: company.id
        }

    })
    const contacts = dataSource?.data?.data?.contacts?.map((contact: any) => {
        return {
            id: contact.id,
            title: contact?.name,
            value: contact.id
        }
    })
    const leadsources = dataSource?.data?.data?.lead_sources?.map((leadsource: any) => {
        return {
            id: leadsource.id,
            title: i18n.language == "en" ? leadsource?.name_en : leadsource?.name_ar,
            value: leadsource.id
        }
    })
    const leadTypes = dataSource?.data?.data?.lead_types?.map((leadType: any) => {
        return {
            id: leadType.id,
            title: i18n.language == "en" ? leadType?.name_en : leadType?.name_ar,
            value: leadType.id
        }
    })
    const inputs: InputsArrayTypes[] = [
        {
            id: 1,
            control: "input",
            as: "",
            label: "Title",
            name: "title"
        },
        {
            id: 2,
            control: "input",
            as: "",
            label: "Expected_Revenue",
            name: "expected_revenue"
        },
        {
            id: 4,
            control: "number",
            label: "Probability",
            name: "probability"
        },
        {
            id: 5,
            control: "date-picker",
            label: "date_of_last_contact",
            name: "date_of_last_contact",
            dateFormat: "YYYY-MM-DD"
        },
        {
            id: 6,
            control: "date-picker",
            label: "date_of_next_contact",
            name: "date_of_next_contact",
            dateFormat: "YYYY-MM-DD"
        },
        {
            id: 7,
            control: "date-picker",
            label: "Expected_Close_Date",
            name: "expected_close_date",
            dateFormat: "YYYY-MM-DD"
        },
        {
            id: 8,
            control: "select",
            label: "company",
            name: "company_id",
            arrayOfOptions: companys
        },
        {
            id: 9,
            control: "select",
            label: "contact",
            name: "contact_id",
            arrayOfOptions: contacts
        },
        {
            id: 10,
            control: "select",
            label: "lead-source",
            name: "lead_source_id",
            arrayOfOptions: leadsources
        },
        {
            id: 11,
            control: "select",
            label: "lead-type",
            name: "lead_type_id",
            arrayOfOptions: leadTypes
        },
        {
            id: 12,
            control: "select",
            label: "status",
            name: "status",
            arrayOfOptions: [
                {
                    id: 1,
                    title: "Open",
                    value: 1
                },
                {
                    id: 2,
                    title: "Lost",
                    value: 2
                },
                {
                    id: 3,
                    title: "Won",
                    value: 3
                }
            ]
        },
        {
            id: 13,
            control: "input",
            label: "notes",
            as: "textarea",
            name: "notes"
        },
        {
            id: 14,
            control: "input",
            as: "",
            label: "value",
            name: "value"
        },
    ]
    return { inputs }
}

export default InputsArray