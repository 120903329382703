import { Dispatch, SetStateAction, useState } from 'react'
import { DropDownType } from '../../../../Models/DropDownType'
import Cookies from 'js-cookie'
const ActionsDropdown = (id: number, handleShow: (name: string) => void, t: any, setRecordId: Dispatch<SetStateAction<number | null>>, permissions: any) => {
    const items: DropDownType[] = [
        {
            id: 1,
            children: t("show"),
            onClick: () => {
                setRecordId(id)
                handleShow("ShowDecision")
            }
        },
        permissions.includes("Make Actions On Administrative Decisions") && {
            id: 2,
            children: t("edit"),
            onClick: () => {
                setRecordId(id)
                handleShow("EditDecisions")
            }
        },

        permissions.includes("Make Actions On Administrative Decisions") && {
            id: 3,
            children: t("Delete"),
            onClick: () => {
                handleShow("Delete")
                setRecordId(id)
            }
        },

    ]

    return { items }
}

export default ActionsDropdown