import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { TableContext } from "../../Context/TableContext"

const ShowModal = () => {
    const { t } = useTranslation()
    const { record } = useContext(TableContext)

    return (
        <>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("name")} : </label>
                <p>{record?.name} </p>
            </div>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("mobile")} :  </label>
                <p>{record?.mobile}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("email")} : </label>
                <p>{record?.email} </p>
            </div>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("job_title")} : </label>
                <p>{record?.job_title} </p>
            </div>
        </>
    )
}

export default ShowModal