import { FC, useCallback, useContext } from 'react'
import { FormikContainer } from '../Forms'
import { InputsArray } from '../../Assets/Data/Dashboard/Lead'
import useQueryPost from '../../Custom Hooks/useQueryPost'
import { useTranslation } from 'react-i18next'
import { FormikHelpers, FormikValues } from 'formik'
import schema from '../../Utils/Validations/Lead/schema'
import { TableContext } from '../../Context/TableContext'
import useQueryGet from '../../Custom Hooks/useQueryGet'
import LeadsService from '../../Services/LeadsService'

type EditModalProps = {
    handleClose: (name: string) => void
}
const EditModal: FC<EditModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [leads] = useQueryGet(() => LeadsService.find(recordId)
        , ["get-single-lead", recordId], undefined, {
        modalLoading: true
    })

    const [handleAdd] = useQueryPost(LeadsService.update, ["edit-lead"], {
        success: t("edited-succssefully"),
    }, ["get-special-events"], () => {
        handleClose("editSpecialEvent")
    })

    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleAdd({ ...values, id: recordId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])
    const initialValues = {
        title: "",
        status: "",
        contact_id: "",
        lead_source_id: "",
        lead_type_id: "",
        company_id: "",
        notes: "",
        date_of_last_contact: "",
        date_of_next_contact: "",
        expected_revenue: "",
        expected_close_date: ""
    }
    return (
        <FormikContainer
            className=''
            arrayOfInputs={InputsArray().inputs}
            initialValues={leads?.data?.data || initialValues}
            onSubmit={onSubmit}
            btnText={t("edit")}
            schema={schema}
            isEnableReinit={true}
        />
    )
}

export default EditModal