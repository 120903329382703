import { Dispatch, SetStateAction } from 'react'
import { DropDownType } from '../../../../Models/DropDownType'
import { NavigateFunction, Navigation } from 'react-router-dom'
const ActionsDropdown = (id: number, handleShow: (name: string) => void, t: any, setRecordId: Dispatch<SetStateAction<number | null>>, navigate: NavigateFunction, permissions: any) => {
    const items: DropDownType[] = [
        permissions.includes("Make Actions On Leads") && {
            id: 1,
            children: t("edit"),
            onClick: () => {
                setRecordId(id)
                handleShow("editLead")
            }
        },
        {
            id: 2,
            children: t("view"),
            onClick: () => {
                setRecordId(id)
                handleShow("viewLead")
            }
        },
        {
            id: 3,
            children: t("show-actions"),
            onClick: () => {
                navigate(`/dashboard/lead-actions/${id}`)
            }
        },
    ]

    return { items }
}

export default ActionsDropdown