import { AddModal, EditModal } from "../../../../Components/LeadSource"
import { useMemo } from 'react'


const LeadSourceModals = (handleClose: (name: string) => void, t: (key: string) => string) => {
    const modals = useMemo(() => {
        return [
            {
                id: 1,
                name: "editLeadSource",
                title: t("edit"),
                children: <EditModal handleClose={() => handleClose("editLeadSource")} />
            },
            {
                id: 2,
                name: "addLeadSource",
                title: t("add"),
                children: <AddModal handleClose={() => handleClose("addLeadSource")} />
            },
        ]
    }, [handleClose])


    return { modals }
}

export default LeadSourceModals