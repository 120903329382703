import { useMemo } from 'react'
import { AddModal, EditModal, ShowModal } from '../../../../Components/Company'



const CompanyModals = (handleClose: (name: string) => void, t: (key: string) => string) => {

    const modals = useMemo(() => {
        return [
            {
                id: 1,
                name: "editCompany",
                title: t("edit"),
                children: <EditModal handleClose={() => handleClose("editCompany")} />
            },
            {
                id: 2,
                name: "addCompany",
                title: t("add"),
                children: <AddModal handleClose={() => handleClose("addCompany")} />
            },
            {
                id: 3,
                name: "viewCompany",
                title: t("View"),
                children: <ShowModal />
            },
        ]
    }, [handleClose])


    return { modals }
}

export default CompanyModals