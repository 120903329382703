import { FC, memo } from "react"
import "./FormikControl.css"
import { DatePickerHour, DatePickerInp, FilesUpload, Input, MultiSelectInp, NumberInput, Select, UploadImg, UploadReport } from "../"


type FormikControlProps = {
    control: string,
    name: string,
    as: string,
    label: string,
    arrayOfOptions?: {
        value: string,
        title: string,
        id: number
    }[],
    isTwoCols?: boolean,
    type?: string,
    dateFormat?: string,
    multiSelectOptions?: any[],
    displayValue?: string,
    selectedList?: any
}
const FormikControl: FC<FormikControlProps> = ({ control, name, as, isTwoCols, label, arrayOfOptions, type, dateFormat, multiSelectOptions, displayValue, selectedList }) => {
    switch (control) {
        case "input": return <Input name={name} as={as} isTwoCols={isTwoCols} label={label} type={type} />
        case "img": return <UploadImg label={label} name={name} />
        case "select": return <Select name={name} isTwoCols={isTwoCols} label={label} arrayOfOptions={arrayOfOptions} />
        case "date-picker": return <DatePickerInp name={name} label={label} dateFormat={dateFormat} isTwoCols={isTwoCols} />
        case "multi-select": return <MultiSelectInp name={name} label={label} options={multiSelectOptions} displayValue={displayValue} selectedList={selectedList} />
        case "hour": return <DatePickerHour name={name} label={label} />
        case "File": return <FilesUpload name={name} label={label} />
        case "Report": return <UploadReport name={name} label={label} />
        case "number": return <NumberInput name={name} label={label} />
        default: return <></>
    }
}

export default memo(FormikControl)