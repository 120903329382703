import { FaInfo } from "react-icons/fa6";
import "./NotificationModal.css"
import useQueryGet from "../../../Custom Hooks/useQueryGet";
import NotificationService from "../../../Services/NotificationService";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { LayoutContext } from "../../../Context/LayoutContext";
import { useInfiniteQuery } from "@tanstack/react-query";
import ModalLoader from "../../ModalLoader/ModalLoader";

const NotificationModal = () => {
    const { i18n } = useTranslation()
    const { isNewNotification, setIsNewNotification } = useContext(LayoutContext)
    const { data, status, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
        queryKey: ["get-all-notification"],
        queryFn: ({ pageParam }) => NotificationService.getAll(pageParam),
        initialPageParam: 1,
        getNextPageParam: (lastPage: any, allPages: any) => {
            return allPages.length == lastPage?.data?.data?.last_page ? undefined : allPages.length + 1
        },
    })
    const notifications = data?.pages?.map(page => {
        return page?.data?.data?.data?.map((notification: any) => {
            const notificationObject = JSON.parse(notification?.data)
            return (
                <div className="col-xs-12 col-sm-12 col-sm-offset-3" key={Math.random()}>
                    <div className="new-message-box">
                        <div className="new-message-box-info">
                            <div className="info-tab tip-icon-info flex-center" title="error">
                                <FaInfo className="icon" />
                            </div>
                            <div className="tip-box-info">
                                <h6>
                                    {i18n.language == "en" ? notificationObject.title_en : notificationObject.title_ar}
                                </h6>
                                <p>
                                    {i18n.language == "en" ? notificationObject.message_en : notificationObject.message_ar}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    })
    useEffect(() => {
        if (isNewNotification == true) {
            setIsNewNotification(false)
        }
    }, [])

    if (status === "pending") {
        return <ModalLoader />
    }
    return (
        <div className="row">
            {notifications}
            <div className="load-more-con flex-center">
                {isFetchingNextPage == true ? <ModalLoader /> :
                    <button onClick={() => fetchNextPage()} disabled={!hasNextPage} className="notification-btn-load-more">load more</button>}
            </div>
        </div>
    )
}

export default NotificationModal