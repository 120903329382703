import { FormikHelpers, FormikValues } from "formik"
import { useTranslation } from "react-i18next"
import useQueryPost from "../../Custom Hooks/useQueryPost"
import { FormikContainer } from "../Forms"
import { schema } from "../../Utils/Validations/Roles/schema"
import { FC, useCallback, useContext, useMemo } from "react"
import { TableContext } from "../../Context/TableContext"
import { InputsArray } from "../../Assets/Data/Dashboard/Roles"
import RolesService from "../../Services/RolesService"
import useQueryGet from "../../Custom Hooks/useQueryGet"
type EditModalProps = {
    handleClose: () => void
}
const EditModal: FC<EditModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const { recordId, } = useContext(TableContext)
    const [dataSource, , , loading] = useQueryGet(() => RolesService.getRoleById(recordId), ["get-role-by-id", recordId], undefined, {
        modalLoading: true
    })
    const [handleSubmit,] = useQueryPost(RolesService.update, ["edit-role"], {
        success: t("edited-succssefully"),
    }, ["get-all-roles"], () => {
        handleClose()
    })

    const initialValues = useMemo(() => {
        return {
            name: dataSource?.data?.data?.role?.name || "",
            permissions: ""

        }
    }, [dataSource])
    const selectedList = useMemo(() => {
        return dataSource?.data?.data?.role?.permissions?.map((permission: any) => {
            return {
                id: permission.id,
                title: permission.name,
                value: permission.id
            }
        })
    }, [dataSource])
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit({ ...values, id: recordId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])

    return (
        <>
            <FormikContainer
                arrayOfInputs={InputsArray(selectedList).inputs}
                className=""

                onSubmit={onSubmit}
                initialValues={initialValues}
                isEnableReinit={true}
                btnText={t("edit")}
                schema={schema} />
        </>
    )
}

export default EditModal