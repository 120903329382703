import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { TableContext } from "../../Context/TableContext"


const ShowModal = () => {
    const { t, i18n } = useTranslation()
    const { record } = useContext(TableContext)

    return (
        <>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("name")} : </label>
                <p>{i18n.language == "en" ? record?.name_en : record?.name_ar} </p>
            </div>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("location")} :  </label>
                <p>{record?.location}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("website")} : </label>
                <p>{record?.website} </p>
            </div>
        </>
    )
}

export default ShowModal