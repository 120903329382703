import { FormikHelpers, FormikValues } from "formik"
import { useTranslation } from "react-i18next"
import useQueryPost from "../../Custom Hooks/useQueryPost"
import { FormikContainer } from "../Forms"
import schema from "../../Utils/Validations/LeadActions/schema"
import { FC, useCallback, useContext } from "react"
import { TableContext } from "../../Context/TableContext"
import { InputsArray } from "../../Assets/Data/Dashboard/LeadActions"
import LeadActionsService from "../../Services/LeadActionsService"
import { useParams } from "react-router-dom"
type AddModalProps = {
    handleClose: () => void
}
const AddModal: FC<AddModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const { id } = useParams()
    const [handleSubmit,] = useQueryPost(LeadActionsService.store, ["add-lead-action"], {
        success: t("added-succssefully"),
    }, ["get-lead-action-by-id"], () => {
        handleClose()
    })
    const initialValues = {
        description: ""
    }
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit({ ...values, lead_id: id, action: 1 })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])
    return (
        <>
            <FormikContainer
                className=""
                arrayOfInputs={InputsArray().inputs}
                onSubmit={onSubmit}
                initialValues={initialValues}
                btnText={t("add")}
                schema={schema} />
        </>
    )
}

export default AddModal