import { useTranslation } from "react-i18next"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import PayrollService from "../../../Services/PayrollService"
import "./SalaryAlert.css"
import { useState } from "react"
import Cookies from "js-cookie"
const SalaryAlert = () => {
    const [data] = useQueryGet(PayrollService.verify, ["verifiedPayroll"])
    const { t } = useTranslation()
    const [permissions, setPermissions] = useState(JSON.parse(Cookies.get("permissions") || ""))
    return (
        <>
            {data?.data?.data == false && permissions.includes("Make Actions On Salary") ? <div className="salray-alert flex-center">
                {t("PleaseDont")}
            </div> : <></>}
        </>
    )
}

export default SalaryAlert