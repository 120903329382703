import { AuthAxios } from "./AxiosHandler"

class ContactService {
    static endPoint = "contact"
    static getAll() {
        return AuthAxios.get(`${ContactService.endPoint}`)
    }
    static store(body: any) {
        return AuthAxios.post(`${ContactService.endPoint}/store`, body)
    }
    static update(body: any) {
        return AuthAxios.post(`${ContactService.endPoint}/update`, body)
    }
    static find(id: number) {
        return AuthAxios.post(`${ContactService.endPoint}/find/${id}`)
    }
}
export default ContactService