import { FC, useCallback } from "react"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn } from "../../../Components"
import { useTranslation } from "react-i18next"
import { BonusTableCols } from "../../../Assets/Data/Dashboard/ProfileMangement/Salary"

type BonusTableProps = {
    handleShow: (name: string) => void,
    loading: boolean,
    bonuses: any,
    permissions: any
}
const BonusTable: FC<BonusTableProps> = ({ handleShow, loading, bonuses, permissions }) => {
    const { t } = useTranslation()
    const { cols } = BonusTableCols(t, handleShow, permissions)
    const memoizedHandleShow = useCallback(() => {
        handleShow("addBonus")
    }, [])
    return (
        <div className="col">
            <BoxWithShadow additionalClass="mb-3 users">
                <div className="flex-between-start">
                    <BoxTitle title={t("TotalBonus")} />
                    {permissions.includes("Make Actions On Salary") && <MainBtn title={t("add")} onClick={memoizedHandleShow} />}

                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={bonuses}
                    columns={cols}
                    pagination={false}
                />
            </BoxWithShadow>
        </div>
    )
}

export default BonusTable