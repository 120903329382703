import { AuthAxios } from "./AxiosHandler"

class LeadTypesService {
    static endPoint = "lead-type"

    static getAll() {
        return AuthAxios.get(`${LeadTypesService.endPoint}`)
    }
    static addLeadType(body: any) {
        return AuthAxios.post(`${LeadTypesService.endPoint}/store`, body)
    }
    static editLeadType(body: any) {
        return AuthAxios.post(`${LeadTypesService.endPoint}/update`, body)
    }
}
export default LeadTypesService