import { AuthAxios } from "./AxiosHandler"

class LeadActionsService {
    static endPoint = "lead-action"
    static getLeadActionsById(id: any) {
        return AuthAxios.get(`${LeadActionsService.endPoint}/${id}`)
    }
    static store(body: any) {
        return AuthAxios.post(`${LeadActionsService.endPoint}/store`, body)
    }
    static update(body: any) {
        return AuthAxios.post(`${LeadActionsService.endPoint}/update`, body)
    }
}
export default LeadActionsService