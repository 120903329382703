import { DropDownMenu, MainBtn } from "../../../../Components"
import useQueryPost from "../../../../Custom Hooks/useQueryPost"
import OutdoorTasksService from "../../../../Services/OutdoorTasksService"
import { ActionsDropdown } from "./"
import { useContext, useMemo } from "react"
import { TableContext } from "../../../../Context/TableContext"

const OutdoorTasksCols = (t: (key: string) => string, handleShow: (name: string) => void) => {
    const { setRecordId, setRecord, record } = useContext(TableContext)
    const [handleApprove] = useQueryPost(OutdoorTasksService.approve, ["approve-outdoor-task"], {
        success: t("approved-successfully"),
    }, ["get-all-outdoor-tasks"])
    const columns = useMemo(() => {
        return [
            {
                title: t("name"),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: t("actions"),
                dataIndex: 'id',
                key: 'id',
                render: (id: number) => <>
                    <DropDownMenu
                        children={t("actions")}
                        arrayOfItems={ActionsDropdown(id, handleShow, t, setRecordId, setRecord, record).items}
                        className="action-table"
                    />
                </>
            },
        ]
    }, [])

    return { columns }
}

export default OutdoorTasksCols