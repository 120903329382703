import * as Yup from "yup"
const schema = Yup.object().shape({
    title: Yup.string().required(),
    status: Yup.string().required(),
    contact_id: Yup.string().required(),
    lead_source_id: Yup.string().required(),
    lead_type_id: Yup.string().required(),
    company_id: Yup.string(),
    notes: Yup.string(),
    date_of_last_contact: Yup.string(),
    date_of_next_contact: Yup.string(),
    expected_revenue: Yup.string(),
    probability: Yup.string().required(),
    expected_close_date: Yup.string()
})
export default schema