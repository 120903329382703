import { FC, useCallback } from "react"
import { FormikContainer } from "../../Forms"
import { LeavesInputs } from "../../../Assets/Data/Dashboard/Calendar"
import { schema } from "../../../Utils/Validations/Leaves/schema"
import { useTranslation } from "react-i18next"
import LeavesServices from "../../../Services/LeavesServices"
import useQueryPost from "../../../Custom Hooks/useQueryPost"
import { FormikHelpers, FormikValues } from "formik"

type AddModalProps = {
    handleClose: () => void
}
const initialValues = {
    start_date: "",
    end_date: "",
    back_to_work_date: "",
    type: "",
    position: "",
}
const AddModal: FC<AddModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const [handleAdd,] = useQueryPost(LeavesServices.post, ["add-leave"], {
        success: t("added-succssefully"),
    }, undefined, () => {
        handleClose()
    })
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {

        handleAdd({ ...values, position: "asd" })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])

    return (
        <FormikContainer
            arrayOfInputs={LeavesInputs(t).inputsArray}
            schema={schema}
            className=""
            btnText={t("add")}
            initialValues={initialValues}
            onSubmit={onSubmit}
            OptionalConfig={{
                isNeedCheck: true,
                checkName: "type",
                checkValue: "1"
            }}
            optionalFileds={["medical_report"]}
        />
    )
}

export default AddModal