import { AuthAxios } from "./AxiosHandler"

class CompanyService {
    static endPoint = "company"
    static getAll() {
        return AuthAxios.get(`${CompanyService.endPoint}`)
    }
    static store(body: any) {
        return AuthAxios.post(`${CompanyService.endPoint}/store`, body)
    }
    static update(body: any) {
        return AuthAxios.post(`${CompanyService.endPoint}/update`, body)
    }
}
export default CompanyService