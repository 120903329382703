import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn, ModalComponent } from "../../../Components"
import "./Contact.css"
import { ToastContainer } from "react-toastify"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import useModals from "../../../Custom Hooks/useModals"
import { useCallback, useState } from "react"
import ContactService from "../../../Services/ContactService"
import { ContactCols } from "../../../Assets/Data/Dashboard/Contact"
import ContactModals from "../../../Assets/Data/Dashboard/Contact/ContactModals"
import Cookies from "js-cookie"

const Contact = () => {
    const { t, i18n } = useTranslation()
    const [page, setPage] = useState<number>(1)
    const [dataSource, , , loading] = useQueryGet(ContactService.getAll, ["get-contact"], undefined, {
        mainLoading: true
    })
    const [permissions, setPermissions] = useState(JSON.parse(Cookies.get("permissions") || ""))
    const { show, handleClose, handleShow } = useModals({
        addContact: false,
        editContact: false,
        viewContact: false
    })
    const { cols } = ContactCols(handleShow, t, i18n.language, permissions)
    const { modals } = ContactModals(handleClose, t)
    const memoizedHandleShow = useCallback(() => {
        handleShow("addContact")
    }, [])
    return (
        <>
            <BoxWithShadow additionalClass={`container-fluid users`}>
                <div className="flex-between-start">
                    <BoxTitle title={t("contact")} />
                    {permissions.includes("Make Actions On Contacts") && <MainBtn title={t("add")} onClick={memoizedHandleShow} />}

                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={dataSource?.data?.data?.data}
                    columns={cols}
                    pagination={{
                        pageSize: dataSource?.data?.data?.per_page,
                        total: dataSource?.data?.data?.total,
                        onChange: (page) => setPage(page)
                    }}
                />
                <ModalComponent show={show} handleClose={handleClose} arrayOfModals={modals} />
                <ToastContainer />
            </BoxWithShadow>

        </>
    )
}

export default Contact