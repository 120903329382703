import { Outlet } from 'react-router-dom'
import UserContextProvider from './Context/UserContext'
import TableContextProvider from './Context/TableContext'
import { QueryClient, QueryClientProvider } from 'react-query';
import { QueryClient as QueryClientt, QueryClientProvider as QueryClientProviderr } from '@tanstack/react-query';
import LoaderContextProvider from './Context/LayoutContext';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            cacheTime: 300000,
            staleTime: Infinity
        }
    }
});
const queryClientt = new QueryClientt({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: Infinity
        }
    }
});

const Root = () => {
    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <QueryClientProviderr client={queryClientt}>
                    <LoaderContextProvider>
                        <UserContextProvider>
                            <TableContextProvider>
                                <Outlet />
                            </TableContextProvider>
                        </UserContextProvider>
                    </LoaderContextProvider>
                </QueryClientProviderr>
            </QueryClientProvider>
        </div>
    )
}

export default Root