import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn, ModalComponent } from "../../../Components"
import "./LeadActions.css"
import { ToastContainer } from "react-toastify"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import useModals from "../../../Custom Hooks/useModals"
import { useCallback, useState } from "react"
import LeadActionsService from "../../../Services/LeadActionsService"
import { useParams } from "react-router-dom"
import { LeadActionsCols, LeadActionsModals } from "../../../Assets/Data/Dashboard/LeadActions"
import Cookies from "js-cookie"

const LeadActions = () => {
    const { t, i18n } = useTranslation()
    const [page, setPage] = useState<number>(1)
    const [permissions, setPermissions] = useState(JSON.parse(Cookies.get("permissions") || ""))
    const params = useParams()
    const [dataSource, , , loading] = useQueryGet(() => LeadActionsService.getLeadActionsById(params.id), ["get-lead-action-by-id", params.id], undefined, {
        mainLoading: true
    })
    const { show, handleClose, handleShow } = useModals({
        addLeadAction: false,
        editLeadAction: false,
        viewLeadAction: false
    })
    const { cols } = LeadActionsCols(handleShow, t, i18n.language, permissions)
    const { modals } = LeadActionsModals(handleClose, t)
    const memoizedHandleShow = useCallback(() => {
        handleShow("addLeadAction")
    }, [])
    return (
        <>
            <BoxWithShadow additionalClass={`container-fluid users`}>
                <div className="flex-between-start">
                    <BoxTitle title={t("lead-actions")} />
                    {permissions.includes("Create Lead Action") && <MainBtn title={t("add")} onClick={memoizedHandleShow} />}

                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={dataSource?.data?.data?.data}
                    columns={cols}
                    pagination={{
                        pageSize: dataSource?.data?.data?.per_page,
                        total: dataSource?.data?.data?.total,
                        onChange: (page) => setPage(page)
                    }}
                />
                <ModalComponent show={show} handleClose={handleClose} arrayOfModals={modals} />
                <ToastContainer />
            </BoxWithShadow>

        </>
    )
}

export default LeadActions