import { useTranslation } from "react-i18next"
import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes"
import { useMemo } from "react"
import useQueryGet from "../../../../Custom Hooks/useQueryGet"
import CompanyService from "../../../../Services/CompanyService"


const InputsArray = () => {
    const { t, i18n } = useTranslation()
    const [dataSource,] = useQueryGet(CompanyService.getAll, ["get-contact"])
    const companys = dataSource?.data?.data?.data?.map((company: any) => {
        if (company?.company) {
            return {
                id: company.company_id,
                title: i18n.language == "en" ? company?.company?.name_en : company?.company?.name_ar,
                value: company.company_id
            }
        }
        else {
            return {
                id: Math.random(),
                title: "Members",
                value: null
            }
        }

    })
    const inputs: InputsArrayTypes[] = useMemo(() => {
        return [
            {
                id: 1,
                control: "input",
                as: "",
                label: "name",
                name: "name"
            },
            {
                id: 2,
                control: "input",
                as: "",
                label: "job_title",
                name: "job_title"
            },
            {
                id: 3,
                control: "input",
                as: "",
                label: "email",
                name: "email",
                type: "email"
            },
            {
                id: 4,
                control: "input",
                as: "",
                label: "mobile",
                name: "mobile",
            },
            {
                id: 7,
                control: "select",
                label: "company",
                name: "company_id",
                arrayOfOptions: companys
            },
        ]
    }, [])
    return { inputs }
}

export default InputsArray