import { ErrorMessage, Field, useField } from 'formik'
import React, { FC } from 'react'
import TextError from '../../TextError/TextError'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
type NumberInputProps = {
    label: string,
    name: string,
}
const NumberInput: FC<NumberInputProps> = ({ label, name }) => {
    const { t } = useTranslation()
    const field = useField(name)
    return (
        <div className="form-control dynamic-input">
            <label htmlFor={label} className="form-label">{t(label)}</label>
            <Field name={name} id={label}  >
                {(props: any) => {
                    return <input type='number' {...props.field} className={clsx(
                        'form-control',
                        { 'is-invalid': field[1].touched && field[1].error },
                        {
                            'is-valid': field[1].touched && !field[1].error,
                        }
                    )} />
                }}
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default NumberInput