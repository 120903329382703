
import { DropDownMenu } from "../../../../Components";
import { useContext, useMemo } from "react";
import { TableContext } from "../../../../Context/TableContext";
import { ActionsDropdown } from "./";
import { useNavigate } from "react-router-dom";



const LeadSourceCols = (handleShow: (name: string) => void, t: (key: string) => string, lang: string, permissions: any) => {
    const { setRecordId } = useContext(TableContext)
    const navigate = useNavigate()
    const cols = useMemo(() => {
        return [
            {
                title: t("user_name"),
                dataIndex: 'user',
                key: 'user',
                render: (record: any) => record?.name
            },
            {
                title: t("Title"),
                dataIndex: 'title',
                key: 'title'
            },
            {
                title: t("contact"),
                dataIndex: 'contact',
                key: 'contact',
                render: (record: any) => record?.name
            },
            {
                title: t("lead-source"),
                dataIndex: 'lead_source',
                key: 'lead_source',
                render: (record: any) => lang == "en" ? record?.name_en : record?.name_ar
            },
            {
                title: t("lead-type"),
                dataIndex: 'lead_type',
                key: 'lead_type',
                render: (record: any) => lang == "en" ? record?.name_en : record?.name_ar
            },
            {
                title: t("actions"),
                dataIndex: 'id',
                key: 'id',
                render: (id: number, record: any) => <>
                    <DropDownMenu
                        className="action-table"
                        arrayOfItems={ActionsDropdown(id, handleShow, t, setRecordId, navigate, permissions).items}
                        children={t("actions")}
                    />
                </>
            }
        ];

    }, [lang])


    return { cols }

}

export default LeadSourceCols