import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn, ModalComponent } from "../../../Components"
import "./Company.css"
import { ToastContainer } from "react-toastify"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import useModals from "../../../Custom Hooks/useModals"
import { useCallback, useState } from "react"
import CompanyService from "../../../Services/CompanyService"
import { CompanyCols, CompanyModals } from "../../../Assets/Data/Dashboard/Company"
import Cookies from "js-cookie"

const Company = () => {
    const { t, i18n } = useTranslation()
    const [page, setPage] = useState<number>(1)
    const [permissions, setPermissions] = useState(JSON.parse(Cookies.get("permissions") || ""))
    const [dataSource, , , loading] = useQueryGet(CompanyService.getAll, ["get-companies"], undefined, {
        mainLoading: true
    })
    const { show, handleClose, handleShow } = useModals({
        addCompany: false,
        editCompany: false,
        viewCompany: false
    })
    const { cols } = CompanyCols(handleShow, t, i18n.language, permissions)
    const { modals } = CompanyModals(handleClose, t)
    const memoizedHandleShow = useCallback(() => {
        handleShow("addCompany")
    }, [])
    return (
        <>
            <BoxWithShadow additionalClass={`container-fluid users`}>
                <div className="flex-between-start">
                    <BoxTitle title={t("companys")} />
                    {permissions.includes("Make Actions On Companies") && <MainBtn title={t("add")} onClick={memoizedHandleShow} />}

                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={dataSource?.data?.data?.data}
                    columns={cols}
                    pagination={{
                        pageSize: dataSource?.data?.data?.per_page,
                        total: dataSource?.data?.data?.total,
                        onChange: (page) => setPage(page)
                    }}
                />
                <ModalComponent show={show} handleClose={handleClose} arrayOfModals={modals} />
                <ToastContainer />
            </BoxWithShadow>

        </>
    )
}

export default Company