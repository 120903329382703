import { FC, memo } from "react"
import "./MultiSelectInp.css"
import { ErrorMessage, Field } from "formik"
import Multiselect from "multiselect-react-dropdown"
import { TextError } from "../../"
import { useTranslation } from "react-i18next"
type MultiSelectInpProps = {
    label: string,
    name: string,
    options?: any[],
    displayValue?: string,
    selectedList?: any
}
const MultiSelectInp: FC<MultiSelectInpProps> = ({ label, name, options, displayValue, selectedList }) => {
    const { t } = useTranslation()
    return (
        <div className="form-control">
            <label htmlFor="role">
                {t(label)}
            </label>
            <Field name={name} id="role" className="form-select">
                {({ field, form }: any) => (
                    <Multiselect
                        onSelect={(selectedList, selectedItem) => {
                            // Update the field value with IDs
                            form.setFieldValue(name, selectedList.map((item: any) => item.id));
                        }}
                        onRemove={(selectedList, removedItem) => {
                            // Update the field value with IDs
                            form.setFieldValue(name, selectedList.map((item: any) => item.id));
                        }}
                        selectedValues={selectedList}
                        {...field}
                        options={options}
                        displayValue={displayValue}
                    />
                )}
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default memo(MultiSelectInp) 