// import { AddModal, EditModal } from "../../../../Components/LeadSource"
import { useMemo } from 'react'
import { AddModal, EditModal } from '../../../../Components/LeadActions'


const LeadActionsModals = (handleClose: (name: string) => void, t: (key: string) => string) => {
    const modals = useMemo(() => {
        return [
            {
                id: 1,
                name: "editLeadAction",
                title: t("edit"),
                children: <EditModal handleClose={() => handleClose("editLeadAction")} />
            },
            {
                id: 2,
                name: "addLeadAction",
                title: t("add"),
                children: <AddModal handleClose={() => handleClose("addLeadAction")} />
            },
        ]
    }, [handleClose])


    return { modals }
}

export default LeadActionsModals