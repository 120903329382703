import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { TableContext } from "../../Context/TableContext"
import useQueryGet from "../../Custom Hooks/useQueryGet"
import LeadsService from "../../Services/LeadsService"


const ShowModal = () => {
    const { t, i18n } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [leads] = useQueryGet(() => LeadsService.find(recordId)
        , ["get-single-lead", recordId], undefined, {
        modalLoading: true
    })
    return (
        <>
            <div className="flex-start-center-row mb-3 show-box show-box-leads">
                <label className="mb-0 mx-2">{t("Title")}  :  </label>
                <p>{leads?.data?.data?.title}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-leads">
                <label className="mb-0 mx-2">{t("Expected_Revenue")} :  </label>
                <p>{leads?.data?.data?.expected_revenue}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-leads">
                <label className="mb-0 mx-2">{t("Probability")} :  </label>
                <p>{leads?.data?.data?.probability}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-leads">
                <label className="mb-0 mx-2">{t("date_of_last_contact")} :  </label>
                <p>{leads?.data?.data?.date_of_last_contact}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-leads">
                <label className="mb-0 mx-2">{t("date_of_next_contact")} :  </label>
                <p>{leads?.data?.data?.date_of_next_contact}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-leads">
                <label className="mb-0 mx-2">{t("Expected_Close_Date")} :  </label>
                <p>{leads?.data?.data?.expected_close_date}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-leads">
                <label className="mb-0 mx-2">{t("contact")} :  </label>
                <p>{leads?.data?.data?.contact?.name}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-leads">
                <label className="mb-0 mx-2">{t("lead-source")} :  </label>
                <p>{i18n.language == "en" ? leads?.data?.data?.lead_source?.name_en : leads?.data?.data?.lead_source?.name_ar}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-leads">
                <label className="mb-0 mx-2">{t("lead-type")} :  </label>
                <p>{i18n.language == "en" ? leads?.data?.data?.lead_type?.name_en : leads?.data?.data?.lead_type?.name_ar}</p>
            </div>
        </>
    )
}

export default ShowModal