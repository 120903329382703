
import { useTranslation } from 'react-i18next'
import { DropDownMenu } from '../../../../../Components'
import SalaryIncreasesActionsDropdown from './SalaryIncreasesActionsDropdown'
import { useContext } from 'react'
import { TableContext } from '../../../../../Context/TableContext'

const SalaryIncreaseTableCols = (t: (key: string) => string, handleShow: (name: string) => void, permissions: any) => {
    const { setRecordId, setTableName } = useContext(TableContext)
    const cols = [
        {
            title: t("amount"),
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: t("date"),
            dataIndex: 'date',
            key: 'date',
        },
        permissions.includes("Make Actions On Salary") ? {
            title: t("actions"),
            dataIndex: 'id',
            key: 'id',
            render: (id: number,) => <>
                <DropDownMenu
                    children={t("actions")}
                    arrayOfItems={SalaryIncreasesActionsDropdown(id, handleShow, t, setRecordId, setTableName).items}
                    className="action-table"
                />

            </>
        } : {
            title: "",
            dataIndex: '',
            key: '',
        },
    ]
    return { cols }
}

export default SalaryIncreaseTableCols