import { AuthAxios } from "./AxiosHandler"

class LeadSourceService {
    static endPoint = "lead-source"
    static getAll() {
        return AuthAxios.get(`${LeadSourceService.endPoint}`)
    }
    static store(body: any) {
        return AuthAxios.post(`${LeadSourceService.endPoint}/store`, body)
    }
    static update(body: any) {
        return AuthAxios.post(`${LeadSourceService.endPoint}/update`, body)
    }
}
export default LeadSourceService