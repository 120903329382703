import { AddModal, EditModal } from '../../../../Components/LeadTypes'
import { useMemo } from 'react'


const LeadTypesModals = (handleClose: (name: string) => void, t: (key: string) => string) => {
    const modals = useMemo(() => {
        return [
            {
                id: 1,
                name: "editLeadType",
                title: t("edit"),
                children: <EditModal handleClose={() => handleClose("editLeadType")} />
            },
            {
                id: 2,
                name: "addLeadType",
                title: t("add"),
                children: <AddModal handleClose={() => handleClose("addLeadType")} />
            },
        ]
    }, [handleClose])


    return { modals }
}

export default LeadTypesModals