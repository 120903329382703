import { Dispatch, SetStateAction } from 'react'
import { DropDownType } from '../../../../Models/DropDownType'
const ActionsDropdown = (id: number, handleShow: (name: string) => void, t: any, setRecordId: Dispatch<SetStateAction<number | null>>, setRecord: Dispatch<SetStateAction<any>>, record: any) => {
    const items: DropDownType[] = [
        {
            id: 1,
            children: t("edit"),
            onClick: () => {
                setRecordId(id)
                handleShow("editLeadSource")
                setRecord(record)
            }
        },
    ]

    return { items }
}

export default ActionsDropdown