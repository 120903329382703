import { useMemo } from "react";
import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes";
import useQueryGet from "../../../../Custom Hooks/useQueryGet";
import RolesService from "../../../../Services/RolesService";
const InputsArray = (selectedList?: any) => {
    const [dataSource, , , loading] = useQueryGet(RolesService.getPermissions, ["get-permissions"], undefined, {
        mainLoading: true
    })

    const permissions = useMemo(() => {
        return dataSource?.data?.data?.map((permission: any) => {
            return {
                id: permission.id,
                title: permission.name,
                value: permission.id
            }
        })
    }, [dataSource])
    const inputs: InputsArrayTypes[] = useMemo(() => {
        return [
            {
                id: 1,
                as: "",
                name: "name",
                label: "name",
                control: "input",
            },
            {
                id: 2,
                control: "multi-select",
                label: "Roles",
                name: "permissions",
                multiSelectOptions: permissions,
                displayValue: "title",
                selectedList: selectedList != null ? selectedList : []
            },
        ]
    }, [permissions, selectedList])

    return { inputs }
}

export default InputsArray