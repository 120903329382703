import { useMemo } from "react";
import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes";
const InputsArray = () => {
    const inputs: InputsArrayTypes[] = useMemo(() => {
        return [
            {
                id: 1,
                as: "",
                name: "name_en",
                label: "name_en",
                control: "input",
            },
            {
                id: 2,
                as: "",
                name: "name_ar",
                label: "name_ar",
                control: "input",
            },
            {
                id: 3,
                as: "",
                name: "location",
                label: "location",
                control: "input",
            },
            {
                id: 4,
                as: "",
                name: "website",
                label: "website",
                control: "input",
            },

        ]
    }, [])

    return { inputs }
}

export default InputsArray