import { ModalComponent } from "../../../../Components"
import "./Salary.css"
import { useParams } from "react-router-dom"
import { useCallback, useContext, useState } from "react"
import { UserContext } from "../../../../Context/UserContext"
import UserService from "../../../../Services/UserService"
import useModals from "../../../../Custom Hooks/useModals"
import { ToastContainer } from "react-toastify"
import { SalaryModals } from "../../../../Assets/Data/Dashboard/ProfileMangement/Salary"
import { BonusTable, DeductionTable, SalaryIncreaseTable, SalaryTable } from "../../../../Sections/ProfileMangement/Salary"
import useQueryGet from "../../../../Custom Hooks/useQueryGet"
import Cookies from "js-cookie"
const Salary = () => {
    const params = useParams()
    const { loginedUserId } = useContext(UserContext)
    const [details, , , loading] = useQueryGet(params.id != null ? () => UserService.payrollDetails(params.id) : () => UserService.payrollDetails(loginedUserId),
        ["get-payroll-details"])
    const [permissions, setPermissions] = useState(JSON.parse(Cookies.get("permissions") || ""))
    const { show, handleShow, handleClose } = useModals({
        addSalaryIncreases: false,
        editSalaryIncreases: false,
        addDeduction: false,
        editDeduction: false,
        addBonus: false,
        editBonus: false,
        Delete: false
    })
    const { modals } = SalaryModals(handleClose)
    const memoizedHandleClose = useCallback((name: string) => {
        handleClose(name)
    }, [handleClose])
    return (
        <>
            <SalaryTable
                loading={loading}
                payrolls={details != undefined ? details?.data?.data?.payrolls : undefined}
            />
            <SalaryIncreaseTable
                permissions={permissions}
                loading={loading}
                salaryIncreases={details?.data?.data?.salary_increases}
                handleShow={handleShow}
            />

            <div className="row row-cols-1 row-cols-md-2 mb-3 salary-cols">
                <DeductionTable
                    permissions={permissions}
                    handleShow={handleShow}
                    loading={loading}
                    deductions={details?.data?.data?.deductions} />
                <BonusTable
                    permissions={permissions}
                    handleShow={handleShow}
                    loading={loading}
                    bonuses={details?.data?.data?.bonuses} />
            </div>

            <ModalComponent arrayOfModals={modals} handleClose={memoizedHandleClose} show={show} />
            <ToastContainer />
        </>
    )
}

export default Salary