import { useMemo } from 'react'
import { AddModal, EditModal, ShowModal } from '../../../../Components/Contact'


const ContactModals = (handleClose: (name: string) => void, t: (key: string) => string) => {

    const modals = useMemo(() => {
        return [
            {
                id: 1,
                name: "editContact",
                title: t("edit"),
                children: <EditModal handleClose={() => handleClose("editContact")} />
            },
            {
                id: 2,
                name: "addContact",
                title: t("add"),
                children: <AddModal handleClose={() => handleClose("addContact")} />
            },
            {
                id: 3,
                name: "viewContact",
                title: t("View"),
                children: <ShowModal />
            },
        ]
    }, [handleClose])


    return { modals }
}

export default ContactModals