import { useMemo } from 'react'
import { AddModal, EditModal, ShowModal } from '../../../../Components/Lead'


const LeadModals = (handleClose: (name: string) => void, t: (key: string) => string) => {
    const modals = useMemo(() => {
        return [
            {
                id: 1,
                name: "editLead",
                title: t("edit"),
                children: <EditModal handleClose={() => handleClose("editLead")} />
            },
            {
                id: 2,
                name: "addLead",
                title: t("add"),
                children: <AddModal handleClose={() => handleClose("addLead")} />
            },
            {
                id: 3,
                name: "viewLead",
                title: t("view"),
                children: <ShowModal />
            },
        ]
    }, [handleClose])


    return { modals }
}

export default LeadModals