import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn, ModalComponent } from "../../Components"
import "./Roles.css"
import { ToastContainer } from "react-toastify"
import useQueryGet from "../../Custom Hooks/useQueryGet"
import useModals from "../../Custom Hooks/useModals"
import { useCallback, useState } from "react"
import { useParams } from "react-router-dom"
import RolesService from "../../Services/RolesService"
import { RolesCols, RolesModals } from "../../Assets/Data/Dashboard/Roles"

const Roles = () => {
    const { t, i18n } = useTranslation()
    const [page, setPage] = useState<number>(1)
    const params = useParams()
    const [dataSource, , , loading] = useQueryGet(RolesService.getAll, ["get-all-roles"], undefined, {
        mainLoading: true
    })
    const { show, handleClose, handleShow } = useModals({
        addRole: false,
        editRole: false,
        viewRole: false
    })
    const { columns } = RolesCols(t, handleShow)
    const { modals } = RolesModals(handleClose, t)
    const memoizedHandleShow = useCallback(() => {
        handleShow("addRole")
    }, [])
    return (
        <>
            <BoxWithShadow additionalClass={`container-fluid users`}>
                <div className="flex-between-start">
                    <BoxTitle title={t("Roles")} />
                    <MainBtn title={t("add")} onClick={memoizedHandleShow} />
                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={dataSource?.data?.data}
                    columns={columns}
                    pagination={false}
                />
                <ModalComponent show={show} handleClose={handleClose} arrayOfModals={modals} />
                <ToastContainer />
            </BoxWithShadow>

        </>
    )
}

export default Roles