
import { DropDownMenu } from "../../../../Components";
import { useContext, useMemo } from "react";
import { TableContext } from "../../../../Context/TableContext";
import { ActionsDropdown } from "./";

const LeadSourceCols = (handleShow: (name: string) => void, t: (key: string) => string, lang: string, permissions: any) => {
    const { setRecordId, setRecord } = useContext(TableContext)
    const cols = useMemo(() => {
        return [
            {
                title: "id",
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: t("name"),
                dataIndex: 'name',
                key: 'name',
                render: (_: any, record: any) => lang == "en" ? record.name_en : record.name_ar
            },
            permissions.includes("Make Actions On Lead Source") ? {
                title: t("actions"),
                dataIndex: 'id',
                key: 'id',
                render: (id: number, record: any) => <>
                    <DropDownMenu
                        className="action-table"
                        arrayOfItems={ActionsDropdown(id, handleShow, t, setRecordId, setRecord, record).items}
                        children={t("actions")}
                    />
                </>
            } : {
                title: "",
                dataIndex: '',
                key: '',
            },
        ];

    }, [lang])


    return { cols }

}

export default LeadSourceCols