import { AuthAxios } from "./AxiosHandler"

class RolesService {
    static endPoint = "roles"
    static getAll() {
        return AuthAxios.get(`${RolesService.endPoint}`)
    }
    static getPermissions() {
        return AuthAxios.get(`${RolesService.endPoint}/get-permissions`)
    }
    static store(body: any) {
        return AuthAxios.post(`${RolesService.endPoint}/store`, body)
    }
    static update(body: any) {
        return AuthAxios.post(`${RolesService.endPoint}/update`, body)
    }
    static getRoleById(id: any) {
        return AuthAxios.get(`${RolesService.endPoint}/edit/${id}`)
    }
}
export default RolesService