
import { DropDownMenu } from "../../../../Components";
import { useContext, useMemo } from "react";
import { TableContext } from "../../../../Context/TableContext";
import { ActionsDropdown } from "./";

const LeadActionsCols = (handleShow: (name: string) => void, t: (key: string) => string, lang: string, permissions: any) => {
    const { setRecordId, setRecord } = useContext(TableContext)
    const cols = useMemo(() => {
        return [
            {
                title: "name",
                dataIndex: 'user',
                key: 'user',
                render: (record: any) => record.name
            },
            {
                title: t("Description"),
                dataIndex: 'description',
                key: 'description'
            },
            permissions.includes("Make Actions On Lead Actions") ? {
                title: t("actions"),
                dataIndex: 'id',
                key: 'id',
                render: (id: number, record: any) => <>
                    <DropDownMenu
                        className="action-table"
                        arrayOfItems={ActionsDropdown(id, handleShow, t, setRecordId, setRecord, record).items}
                        children={t("actions")}
                    />
                </>
            } : {
                title: "",
                dataIndex: '',
                key: '',
            },
        ];

    }, [lang])


    return { cols }

}

export default LeadActionsCols